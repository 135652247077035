<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-container class="bg-surface-variant">
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="2"
              style=""
            >
              <v-container class="bg-surface-variant">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="12"
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    <div>
                      UPA
                    </div>
                    <div>
                      unique player availabity
                    </div>
                  </v-col>
                </v-row>
                <v-row
                  v-for="i in 12"
                  :key="i"
                  no-gutters
                >
                  <v-col
                    cols="12"
                    sm="12"
                    style=""
                    class="d-flex justify-center"
                  >
                    <v-checkbox></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col
              cols="12"
              sm="10"
              style=""
            >
              <v-container class="bg-surface-variant, custom-container">
                <v-row style="height: 75px;">
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    TIME
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style=" margin-bottom: auto; margin-top: auto; text-align: center;width: 12.5%;"
                  >
                    <v-checkbox label="MON"></v-checkbox>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center;width: 12.5%;"
                  >
                    <v-checkbox label="TUE"></v-checkbox>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center;width: 12.5%;"
                  >
                    <v-checkbox label="WED"></v-checkbox>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center;width: 12.5%;"
                  >
                    <v-checkbox label="THU"></v-checkbox>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center;width: 12.5%;"
                  >
                    <v-checkbox label="FRI"></v-checkbox>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center;width: 12.5%;"
                  >
                    <v-checkbox label="SAT"></v-checkbox>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center;width: 12.5%;"
                  >
                    <v-checkbox label="SUN"></v-checkbox>
                  </v-col>
                </v-row>
                <v-row style="height: 66px;">
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center;width: 12.5%;"
                  >
                    {{ "05.00 PM" }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style=" margin-bottom: auto; margin-top: auto; text-align: center;width: 12.5%;"
                  >
                    {{ 12 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center;width: 12.5%;"
                  >
                    {{ 16 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 14 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 8 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 5 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 10 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 6 }}
                  </v-col>
                </v-row>
                <v-row style="height: 66px;">
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ "05.30 PM" }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style=" margin-bottom: auto; margin-top: auto; text-align: center;"
                  >
                    {{ 21 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 32 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 21 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 46 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 11 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 14 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 9 }}
                  </v-col>
                </v-row>
                <v-row style="height: 66px;">
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ "06.00 PM" }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style=" margin-bottom: auto; margin-top: auto; text-align: center;"
                  >
                    {{ 43 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 55 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 39 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 29 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 15 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 19 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 11 }}
                  </v-col>
                </v-row>
                <v-row style="height: 66px;">
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ "06.30 PM" }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style=" margin-bottom: auto; margin-top: auto; text-align: center;"
                  >
                    {{ 58 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 50 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 44 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 31 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 19 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 20 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 13 }}
                  </v-col>
                </v-row>
                <v-row style="height: 66px;">
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ "07.00 PM" }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style=" margin-bottom: auto; margin-top: auto; text-align: center;"
                  >
                    {{ 63 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 63 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 52 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 33 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 22 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 23 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 15 }}
                  </v-col>
                </v-row>
                <v-row style="height: 66px;">
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ "07.30 PM" }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style=" margin-bottom: auto; margin-top: auto; text-align: center;"
                  >
                    {{ 50 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 65 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 56 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 35 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 20 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 19 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 16 }}
                  </v-col>
                </v-row>
                <v-row style="height: 66px;">
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ "08.00 PM" }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style=" margin-bottom: auto; margin-top: auto; text-align: center;"
                  >
                    {{ 43 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 51 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 55 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 30 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 19 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 19 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 12 }}
                  </v-col>
                </v-row>
                <v-row style="height: 66px;">
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ "08.30 PM" }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style=" margin-bottom: auto; margin-top: auto; text-align: center;"
                  >
                    {{ 47 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 53 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 48 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 28 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 15 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 18 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 8 }}
                  </v-col>
                </v-row>
                <v-row style="height: 66px;">
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ "09.00 PM" }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style=" margin-bottom: auto; margin-top: auto; text-align: center;"
                  >
                    {{ 23 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 45 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 45 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 20 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 12 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 15 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 8 }}
                  </v-col>
                </v-row>
                <v-row style="height: 66px;">
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ "09.30 PM" }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style=" margin-bottom: auto; margin-top: auto; text-align: center;"
                  >
                    {{ 38 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 48 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 38 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 25 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 15 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 17 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 10 }}
                  </v-col>
                </v-row>
                <v-row style="height: 66px;">
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ "10.00 PM" }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style=" margin-bottom: auto; margin-top: auto; text-align: center;"
                  >
                    {{ 41 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 59 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 51 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 40 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 17 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 21 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 9 }}
                  </v-col>
                </v-row>
                <v-row style="height: 66px;">
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ "10.30 PM" }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style=" margin-bottom: auto; margin-top: auto; text-align: center;"
                  >
                    {{ 29 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 28 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 41 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 19 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 20 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 14 }}
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    style="margin-bottom: auto; margin-top: auto; text-align: center; width: 12.5%;"
                  >
                    {{ 7 }}
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mdiAlertOutline, mdiCloudUploadOutline, mdiCamera, mdiMenuDown } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import { required, emailValidator, between, integerValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import { useRouter } from '@core/utils'
import { getPaymentById } from '@api'

export default {
  props: {
    option: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    dataTransaction: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const form = ref(null)
    const { router } = useRouter()
    const { t } = useUtils()
    const initData = ref({
      end_time: null,
      facility_address: null,
      facility_avatar: null,
      facility_name: null,
      field_name: null,
      id: null,
      left_thumbnail: null,
      link: null,
      right_thumbnail: null,
      start_time: null,
      status: null,
      status_str: null,
      thumbnail: null,
      transaction: {
        transaction_date: null,
        user: {
          fullname: null,
        },
      },
      video_date: null,
      video_time: null,
      video_time_str: null,
    })

    const dataComputed = computed(() => (props.dataParams ? props.dataParams : initData.value))

    const statusOptions = [
      { text: 'Active', value: 'A' },
      { text: 'Inactive', value: 'I' },
    ]

    const resolveStatusVariant = status => {
      if (status === 'primary') return 'primary'
      if (status === 'N') return 'error'
      if (status === 'secondary') return 'secondary'
      if (status === 'warning') return 'warning'
      if (status === 'F') return 'info'
      if (status === 'P') return 'success'

      return 'primary'
    }

    const resolveStatusVideo = status => {
      if (status === 'primary') return 'primary'
      if (status === 'R') return 'error'
      if (status === 'secondary') return 'secondary'
      if (status === 'P') return 'warning'
      if (status === 'S') return 'info'
      if (status === 'A') return 'success'

      return 'primary'
    }

    const onShow = async item => {
      const response = await getPaymentById(item.transaction.payment.id)
      if (response.ok) {
        const payment = response.data
        router.push({
          name: 'views-payment-preview',
          params: {
            id: item.id,
            dataParams: payment,
            dataVideo: item,
            dataTransaction: props.dataTransaction,
            option: 2,
          },
        })
      }
    }

    return {
      // data
      form,
      statusOptions,

      // computed
      dataComputed,

      // methods
      avatarText,
      resolveStatusVariant,
      resolveStatusVideo,
      onShow,

      // validations
      required,
      emailValidator,
      between,
      integerValidator,

      // i18n
      t,

      // icons
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiCamera,
        mdiMenuDown,
      },
    }
  },
}
</script>

<style lang="scss" scope>

.custom-container {
  border-radius: 10px;
  border: 1px solid #ccc;
}

.custom-container > * {
  border-bottom: 1px solid #ccc;
}

.custom-container > *:last-child {
  border-bottom: none;
}

.custom-container > .v-row {
  border-right: 1px solid #ccc;
}

.custom-container > .v-row:last-child {
  border-right: none;
}

div .v-input__prepend-outer {
  margin: 0px 8px 0px 0px !important;
}
</style>
