<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-container class="bg-surface-variant">
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="6"
        >
          <v-sheet class="ma-2 pa-2">
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          style="margin-bottom: auto; margin-top: auto; text-align: center;"
        >
          <v-btn-toggle
            mandatory
          >
            <v-btn>
              {{ "CURRENT" }}
            </v-btn>

            <v-btn>
              {{ t('tooltip.prev') }}
            </v-btn>

            <v-btn>
              {{ t('tooltip.next') }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="6"
        >
          <v-sheet class="ma-2 pa-2">
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          sm="2"
          style="margin-bottom: auto; margin-top: auto; text-align: center;"
        >
          <v-container class="bg-surface-variant">
            <v-row no-gutters>
              <v-col
                cols="12"
                sm="12"
                style="margin-bottom: auto; margin-top: auto; text-align: center;"
              >
                {{ dataComputed.prev_month_label }}
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col
          cols="12"
          sm="1"
          style="text-align: center; margin-bottom: auto; margin-top: auto;"
        >
          Month over Month
        </v-col>
        <v-col
          cols="12"
          sm="2"
          style="text-align: center; margin-bottom: auto; margin-top: auto;"
        >
          {{ dataComputed.month_label }}
        </v-col>
        <v-col
          cols="12"
          sm="1"
        >
          <v-container class="bg-surface-variant">
            <v-row no-gutters>
              <v-col
                cols="12"
                sm="12"
                style="margin-bottom: auto; margin-top: auto; text-align: center;"
              >
                {{ `Month over Month ` }}
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        style="border-top: 1px bisque solid; padding-top: 10px;"
      >
        <v-col
          cols="12"
          sm="6"
          style="vertical-align: middle; padding-left: 10px; font-weight: bold; font-size: large;"
        >
          Number of groups
        </v-col>
        <v-col
          cols="12"
          sm="2"
          style="vertical-align: middle; padding-left: 10px; font-weight: bold; font-size: large; text-align: center;"
        >
          {{ dataComputed.prev_groups_qty }}
        </v-col>
        <v-col
          cols="12"
          sm="1"
          :style="{ 'vertical-align': 'middle', 'padding-left': '10px', 'font-weight': 'bold', 'font-size': 'medium', 'text-align': 'center', 'color': dataComputed.diff_groups_qty >0 ? 'green' : 'red'}"
        >
          {{ `${dataComputed.diff_groups_qty > 0 ? '+' : ''}${(dataComputed.diff_groups_qty*100).toFixed(2)}%` }}
        </v-col>
        <v-col
          cols="12"
          sm="2"
          style="vertical-align: middle; padding-left: 10px; font-weight: bold; font-size: large; text-align: center;"
        >
          {{ dataComputed.groups_qty }}
        </v-col>
        <v-col
          cols="12"
          sm="1"
          :style="{ 'vertical-align': 'middle', 'padding-left': '10px', 'font-weight': 'bold', 'font-size': 'medium', 'text-align': 'center', 'color': dataComputed.diff_prev_groups_qty >0 ? 'green' : 'red'}"
        >
          {{ `${dataComputed.diff_prev_groups_qty > 0 ? '+' : ''}${(dataComputed.diff_prev_groups_qty*100).toFixed(2)}%` }}
        </v-col>
      </v-row>
      <v-row
        no-gutters
        style="border-top: 1px bisque solid; padding-top: 10px;"
      >
        <v-col
          cols="12"
          sm="6"
          style="vertical-align: middle; padding-left: 10px; font-weight: bold; font-size: large;"
        >
          Total Number of players
        </v-col>
        <v-col
          cols="12"
          sm="2"
          style="vertical-align: middle; padding-left: 10px; font-weight: bold; font-size: large; text-align: center;"
        >
          {{ dataComputed.prev_players_qty }}
        </v-col>
        <v-col
          cols="12"
          sm="1"
          :style="{ 'vertical-align': 'middle', 'padding-left': '10px', 'font-weight': 'bold', 'font-size': 'medium', 'text-align': 'center', 'color': dataComputed.diff_players_qty >0 ? 'green' : 'red'}"
        >
          {{ `${dataComputed.diff_players_qty > 0 ? '+' : ''}${(dataComputed.diff_players_qty*100).toFixed(2)}%` }}
        </v-col>
        <v-col
          cols="12"
          sm="2"
          style="vertical-align: middle; padding-left: 10px; font-weight: bold; font-size: large; text-align: center;"
        >
          {{ dataComputed.players_qty }}
        </v-col>
        <v-col
          cols="12"
          sm="1"
          :style="{ 'vertical-align': 'middle', 'padding-left': '10px', 'font-weight': 'bold', 'font-size': 'medium', 'text-align': 'center', 'color': dataComputed.diff_prev_players_qty >0 ? 'green' : 'red'}"
        >
          {{ `${dataComputed.diff_prev_players_qty > 0 ? '+' : ''}${(dataComputed.diff_prev_players_qty*100).toFixed(2)}%` }}
        </v-col>
      </v-row>
      <v-row
        no-gutters
        style="border-top: 1px bisque solid; padding-top: 10px;"
      >
        <v-col
          cols="12"
          sm="6"
          style="vertical-align: middle; padding-left: 10px; font-weight: bold; font-size: large;"
        >
          Average Number of players per Group
        </v-col>
        <v-col
          cols="12"
          sm="2"
          style="vertical-align: middle; padding-left: 10px; font-weight: bold; font-size: large; text-align: center;"
        >
          {{ dataComputed.prev_avg_play_group }}
        </v-col>
        <v-col
          cols="12"
          sm="1"
          :style="{ 'vertical-align': 'middle', 'padding-left': '10px', 'font-weight': 'bold', 'font-size': 'medium', 'text-align': 'center', 'color': dataComputed.diff_avg_play_group >0 ? 'green' : 'red'}"
        >
          {{ `${dataComputed.diff_avg_play_group > 0 ? '+' : ''}${(dataComputed.diff_avg_play_group*100).toFixed(2)}%` }}
        </v-col>
        <v-col
          cols="12"
          sm="2"
          style="vertical-align: middle; padding-left: 10px; font-weight: bold; font-size: large; text-align: center;"
        >
          {{ dataComputed.avg_play_group }}
        </v-col>
        <v-col
          cols="12"
          sm="1"
          :style="{ 'vertical-align': 'middle', 'padding-left': '10px', 'font-weight': 'bold', 'font-size': 'medium', 'text-align': 'center', 'color':( dataComputed.diff_prev_avg_play_group >0 ? 'green' : 'red')}"
        >
          {{ `${dataComputed.diff_prev_avg_play_group > 0 ? '+' : ''}${(dataComputed.diff_prev_avg_play_group*100).toFixed(2)}%` }}
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mdiAlertOutline, mdiCloudUploadOutline, mdiCamera, mdiMenuDown } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import { required, emailValidator, between, integerValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import { useRouter } from '@core/utils'
import { getPaymentById } from '@api'

export default {
  props: {
    option: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    dataTransaction: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const form = ref(null)
    const { router } = useRouter()
    const { t } = useUtils()
    const initData = ref({})

    const dataComputed = computed(() => (props.dataParams ? props.dataParams : initData.value))

    const statusOptions = [
      { text: 'Active', value: 'A' },
      { text: 'Inactive', value: 'I' },
    ]

    const resolveStatusVariant = status => {
      if (status === 'primary') return 'primary'
      if (status === 'N') return 'error'
      if (status === 'secondary') return 'secondary'
      if (status === 'warning') return 'warning'
      if (status === 'F') return 'info'
      if (status === 'P') return 'success'

      return 'primary'
    }

    const resolveStatusVideo = status => {
      if (status === 'primary') return 'primary'
      if (status === 'R') return 'error'
      if (status === 'secondary') return 'secondary'
      if (status === 'P') return 'warning'
      if (status === 'S') return 'info'
      if (status === 'A') return 'success'

      return 'primary'
    }

    const onShow = async item => {
      const response = await getPaymentById(item.transaction.payment.id)
      if (response.ok) {
        const payment = response.data
        router.push({
          name: 'views-payment-preview',
          params: {
            id: item.id,
            dataParams: payment,
            dataVideo: item,
            dataTransaction: props.dataTransaction,
            option: 2,
          },
        })
      }
    }

    return {
      // data
      form,
      statusOptions,

      // computed
      dataComputed,

      // methods
      avatarText,
      resolveStatusVariant,
      resolveStatusVideo,
      onShow,

      // validations
      required,
      emailValidator,
      between,
      integerValidator,

      // i18n
      t,

      // icons
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiCamera,
        mdiMenuDown,
      },
    }
  },
}
</script>

<style lang="scss" scope>
div .v-input__prepend-outer {
  margin: 0px 8px 0px 0px !important;
}
</style>
